<!-- eslint-disable no-console -->
<script>
import appConfig from "@/app.config";
import { authMethods } from "@/state/helpers";

/**
 * Login component
 */
export default {
  page: {
    title: "Login",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: {},
  data() {
    return {
      email: "developer@mail.com",
      password: "admin123!",
      authError: null,
      tryingToLogIn: false,
      isAuthError: false,
    };
  },
  methods: {
    ...authMethods,
    // Try to log the user in with the username
    // and password they provided.
    tryToLogIn() {
      this.tryingToLogIn = true;
      // Reset the authError if it existed.
      this.authError = null;
      return (
        this.logIn({
          email: this.email,
          password: this.password,
        })
          // eslint-disable-next-line no-unused-vars
          .then((token) => {
            this.tryingToLogIn = false;
            this.isAuthError = false;
            // Redirect to the originally requested page, or to the home page
            this.$router.push(
              this.$route.query.redirectFrom || { name: "home" }
            );
          })
          .catch((error) => {
            this.tryingToLogIn = false;
            this.authError = error ? error.response.data.message : "";
            this.isAuthError = true;
          })
      );
    },
  },
};
</script>

<template>
  <div class="account-pages my-5 pt-5">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-md-8 col-lg-6 col-xl-5">
          <div class="card overflow-hidden">
            <div class="text-primary text-center p-4">
              <h1>Login</h1>
            </div>
            <div class="card-body px-4 pt-0">
              <div class="p-3">
                <b-alert
                  v-model="isAuthError"
                  variant="danger"
                  class="mt-3"
                  dismissible
                  >{{ authError }}</b-alert
                >

                <ValidationObserver v-slot="{ handleSubmit }">
                  <b-form
                    @submit.prevent="handleSubmit(tryToLogIn)"
                    class="form-horizontal mt-4"
                  >
                    <ValidationProvider
                      name="Email"
                      rules="required|email"
                      v-slot="{ errors }"
                    >
                      <b-form-group
                        id="input-group-1"
                        label="Email"
                        label-for="input-1"
                      >
                        <b-form-input
                          id="input-1"
                          v-model="email"
                          type="email"
                        ></b-form-input>
                        <p class="fs-6 text-danger">
                          {{ errors[0] }}
                        </p>
                      </b-form-group>
                    </ValidationProvider>

                    <ValidationProvider
                      name="Password"
                      rules="required"
                      v-slot="{ errors }"
                    >
                      <b-form-group
                        id="input-group-2"
                        label="Password"
                        label-for="input-2"
                        class="mb-3"
                      >
                        <b-form-input
                          id="input-2"
                          v-model="password"
                          type="password"
                        ></b-form-input>
                        <p class="fs-6 text-danger">
                          {{ errors[0] }}
                        </p>
                      </b-form-group>
                    </ValidationProvider>

                    <div class="form-group row">
                      <div class="col-sm-12 text-right">
                        <router-link tag="a" to="/forgot-password">
                          lupa password?
                        </router-link>
                      </div>
                    </div>

                    <div class="form-group row">
                      <div class="col-sm-12">
                        <b-button type="submit" variant="primary" class="w-100"
                          >Login</b-button
                        >
                      </div>
                    </div>
                  </b-form>
                </ValidationObserver>
              </div>
            </div>
            <!-- end card-body -->
          </div>
          <!-- end card -->
        </div>
        <!-- end col -->
      </div>
      <!-- end row -->
    </div>
  </div>
</template>

<style lang="scss" module></style>
